<template>
	<a-row type="flex" :gutter="24">
        <a-col :span="24" :md="17" class="mb-20">
            <a-card :bordered="false" class="mb-15">
                <template #title>
                    <h6 class="font-semibold m-0">1. {{ $t('server.actionCreate.titleConfig') }}</h6>
                </template>
                <WidgetSliderConfig 
                    v-model="form.config"
                    :show="{
                        cpu: false,
                        ram: false,
                        disk: true,
                    }"
                />
            </a-card>
            <a-card :bordered="false" class="mb-15">
                <template #title>
                    <h6 class="font-semibold m-0">2. {{ $t('server.actionCreate.paymentTerm') }}</h6>
                </template>
                <WidgetSelectTerm
                    :params="form.config"
                    :type="form.type"
                    v-model="WidgetSelectTermData"
                />
            </a-card>
            <a-card :bordered="false" class="mb-15">
                <template #title>
                    <h6 class="font-semibold m-0">3. {{ $t('volume.actionCreate.invoiceSidebar.title') }}</h6>
                </template>
                <a-form :form="volumeInfo" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" @submit="handleSubmit">
                    <a-alert class="mb-10" show-icon :message="$t('volume.actionCreate.form.desc')" type="info" />
                    <a-alert class="mb-10" show-icon :message="$t('volume.actionCreate.descSelectSnapshot')" type="warning" />
                    <a-form-item :label="$t('volume.actionCreate.form.title')">
                        <a-input
                            v-decorator="['name', { rules: rules.name }]"
                        />
                    </a-form-item>
                    <a-form-item :label="$t('volume.actionCreate.titleSelectSnapshot')">
                        <a-select
                            :options="snapshots"
                            v-decorator="['snapshot_id']"
                            :placeholder="$t('volume.actionCreate.placeholderSelectSnapshot')"
                        >
                        </a-select>
                    </a-form-item>
                    <a-form-item :label="$t('volume.actionCreate.form.titleSelectServer')">
                        <a-select
                            :options="servers"
                            :placeholder="$t('volume.actionCreate.form.placeholderSelectServer')"
                            v-decorator="['server_id']"
                        >
                        </a-select>
                    </a-form-item>
                    <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
                        <a-button 
                            type="primary" 
                            html-type="submit"
                            :loading="fetchCreateOrder"
                            >{{ $t('volume.actionCreate.form.btnCreate') }}
                        </a-button>
                    </a-form-item> 
                </a-form>
            </a-card>
        </a-col>
		<a-col :span="24" :md="7" class="mb-24">
		</a-col>
	</a-row>
</template>

<script>

    import WidgetSliderConfig from "@/components/Widgets/WidgetSliderConfig"
    import WidgetSelectTerm from "@/components/Widgets/WidgetSelectTerm"
    import OrderService from '@/services/order'
    import { notification } from 'ant-design-vue'
    import servers from "@/services/server/listing"
    import {PATTERN_NAME, ORDER_TYPE_VOLUME, SNAPSHOT_ACTIVE, STORAGE_UNIT} from "@/constant"
    import snapshots from "@/services/snapshot/listing"

	export default ({
		components: {
            WidgetSliderConfig,
            WidgetSelectTerm,
		},
		data() {
			return {
                SNAPSHOT_ACTIVE,
                form: {
                    type: ORDER_TYPE_VOLUME,
                    config: {},
                    info: {},
                    interval: ''
                },
                rules: {
                    name: [
                        { required: true, message: this.$t('rulesForm.requiredVolumeName'), trigger: ['blur', 'change'] },
                        { min: 5, max: 32, message: this.$t('rulesForm.invalidVolumeCharactersLimited'), trigger: ['blur', 'change'] },
                        { pattern: PATTERN_NAME,
                        message: this.$t('rulesForm.invalidVolumeName'),
                        trigger: ['blur', 'change']
                        }
                    ]
                },
                volumeInfo: this.$form.createForm(this, { name: 'volumeInfo' }),
                WidgetSelectTermData: {},
                fetchCreateOrder: false,
                fetchSnapshot: true,
                servers: [],
                snapshots: [],
			}
		},
        watch: {
            WidgetSelectTermData: function (val) {
                this.form.interval = val.interval
            }
        },
        created() {
			servers()
			.then(res=> {
				if (this._.isObject(res)) {
                    this._.forEach(res, (row)=> {
                        this.servers.push({
                            label: row.name,
                            value: row.id
                        })
                    })
				}
			})
            snapshots()
			.then(res=> {
				if (this._.isObject(res)) {
                    this._.forEach(res, (row)=> {
                        if (row.status !== SNAPSHOT_ACTIVE) return
                        this.snapshots.push({
                            label: `${row.name} (${row.volume.name} / ${row.volume.volume_type.toUpperCase()} ${row.size} ${STORAGE_UNIT})`,
                            value: row.id
                        })
                    })
                    this.fetchSnapshot = false
				}
			})
		},
        methods : {
            handleSubmit(e) {
                e.preventDefault();
                this.volumeInfo.validateFields((err, values) => {
                    if (err) {
                        return;
                    }
                    console.log(this.form)
                    this.form.info.name = values.name
                    this.form.info.snapshot_id  = values.snapshot_id === undefined ? '' : values.snapshot_id
                    this.form.info.server_id    = values.server_id === undefined ? '' : values.server_id

                    let messages = []
                    
                    if (this.form.config.disk < 1)
                        messages.push('Bạn chưa chọn cấu hình')
                    
                    if (this.form.interval === '')
                        messages.push('Bạn chưa chọn kỳ hạn thanh toán')
                    
                    if (!this._.isEmpty(messages)) {
                        this._.forEach(messages, val => {
                            notification.error({
                                message: val
                            })
                        })
                        return
                    }

                    this.createOrder()
                });
            },
            createOrder() {
                this.fetchCreateOrder = true
                OrderService.create(this.form)
                .then(res => {
                    this.fetchCreateOrder = false
                    if (this._.isObject(res) && res.status == 1) {
                        notification.success({
                            message: 'Tạo đơn hàng thành công'
                        })
                        this.$router.push({name: 'billing.payment', params: {id: res.data.invoice_id}})
                    } else {
                        notification.error({
                            message: res.message
                        })
                    }
                })
            }
        }
	})

</script>

<style lang="scss">
</style>